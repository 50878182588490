'use client';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getClientComponentClient } from '@/utils/clientSupaUtils';
import { Small } from '@/components/Typography/Small';
import { H2 } from '@/components/Typography/H2';
import { Large } from '@/components/Typography/Large';
import { P } from '@/components/Typography/P';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import GoogleIcon from '../GoogleIcon';
import { CheckCircleIcon, Mail } from 'lucide-react';
import { log } from '@/utils/logger';
import { toastError } from '@/types/general';
import MicrosoftIcon from '../MicrosoftIcon';
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile';
import { getEnvironmentVariable } from '@/utils/envVars';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/Dialog';
import { usePathname, useRouter } from 'next/navigation';
import { removeFromURL } from '@/utils/random';

interface Props {
    invited: boolean;
}
export default function SigninForm({ invited }: Props) {
    const supabase = getClientComponentClient();
    const [loading, setLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState<string>('');
    const [showInvitedDialog, setShowInvitedDialog] = useState(false);
    const router = useRouter();
    const pathname = usePathname();
    const turnRef = useRef<TurnstileInstance>(null);

    useEffect(() => {
        if (invited) {
            const url = removeFromURL('invited', pathname);
            router.replace(url);
            setShowInvitedDialog(true);
        }
    }, [invited, router, pathname]);

    const handleSignInWithEmail = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            // Get email from form
            const email = e.currentTarget?.email?.value as string | undefined;

            if (!email) {
                toastError('Please enter your email address.');
                return;
            }

            setLoading(true);
            const response = await supabase.auth.signInWithOtp({
                email,
                options: {
                    captchaToken,
                },
            });

            if (response.error) {
                log({
                    message: 'Error signing in with email',
                    error: response.error,
                    extra: {
                        email,
                    },
                    level: 'error',
                });

                toastError(response.error.message);
                setLoading(false);
            } else {
                turnRef.current?.remove();
                router.push(`/auth/confirm?email=${email}`);
            }
        },
        [captchaToken, supabase, router]
    );

    return (
        <>
            <Dialog open={showInvitedDialog} onOpenChange={setShowInvitedDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle className="flex flex-row items-center text-xl font-semibold text-success-foreground">
                            <CheckCircleIcon className="mr-2 size-5" /> Invite Accepted!
                        </DialogTitle>
                        <DialogDescription>
                            You have successfully accepted the invite. Please sign in using the{' '}
                            <span className="font-semibold">same email address</span> that the
                            invite was sent to.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button onClick={() => setShowInvitedDialog(false)}>Okay</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <div className="w-full border-border lg:w-1/3 lg:border-l-2">
                <div className="w-full p-4 sm:p-12.5 lg:p-17.5">
                    <Link className="mb-2 inline-block lg:hidden" href="https://pitchghost.com">
                        <Image
                            src="/images/logo/v2/pitchghost_logo_colored.svg"
                            alt="Logo"
                            width={250}
                            height={40}
                            className="dark:hidden"
                            unoptimized={true}
                        />
                        <Image
                            src="/images/logo/v2/pitchghost_logo_white.svg"
                            alt="Logo"
                            width={250}
                            height={40}
                            className="hidden dark:block"
                            unoptimized={true}
                        />
                    </Link>
                    <H2 className="mb-9">Sign in to PitchGhost</H2>

                    <div className="flex flex-col gap-4">
                        <Button
                            onClick={async () => {
                                try {
                                    const response = await supabase.auth.signInWithOAuth({
                                        provider: 'google',
                                        options: {
                                            redirectTo: `${location.origin}/auth/callback`,
                                        },
                                    });

                                    if (response.error) {
                                        throw response.error;
                                    }
                                } catch (error) {
                                    log({
                                        message: 'Error signing in with Google',
                                        error,
                                        level: 'error',
                                    });
                                    toastError('Something went wrong. Please try again.');
                                }
                            }}
                            autoSetLoadingState
                            variant={'outline'}
                            size={'fullWidth'}
                            className="gap-2"
                            type="button"
                        >
                            <GoogleIcon className="size-5" />
                            <Small>Google</Small>
                        </Button>

                        <Button
                            onClick={async () => {
                                try {
                                    const response = await supabase.auth.signInWithOAuth({
                                        provider: 'azure',
                                        options: {
                                            redirectTo: `${location.origin}/auth/callback`,
                                            scopes: 'email',
                                        },
                                    });

                                    if (response.error) {
                                        throw response.error;
                                    }
                                } catch (error) {
                                    log({
                                        message: 'Error signing in with Microsoft',
                                        error,
                                        level: 'error',
                                    });
                                    toastError('Something went wrong. Please try again.');
                                }
                            }}
                            autoSetLoadingState
                            variant={'outline'}
                            size={'fullWidth'}
                            className="gap-2"
                            type="button"
                        >
                            <MicrosoftIcon className="size-5" />
                            <Small>Microsoft</Small>
                        </Button>
                    </div>

                    <form onSubmit={handleSignInWithEmail}>
                        <div className="my-5 flex items-center gap-3.5">
                            <hr className="flex-1 border border-muted" />
                            <Large className="text-muted-foreground">OR</Large>
                            <hr className="flex-1 border border-muted" />
                        </div>
                        <div className="mb-4 grid w-full items-center gap-2">
                            <Label htmlFor="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                disabled={loading}
                                placeholder="Enter your email"
                                className="h-13"
                                required
                                icon={<Mail className="size-4 text-muted-foreground" />}
                            />
                            <Turnstile
                                ref={turnRef}
                                siteKey={getEnvironmentVariable(
                                    process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY
                                )}
                                onSuccess={(token) => {
                                    setCaptchaToken(token);
                                }}
                                options={{
                                    appearance: 'always',
                                    size: 'normal',
                                    refreshExpired: 'auto',
                                    theme: 'light',
                                    execution: 'render',
                                    action: 'email-signin',
                                }}
                                onError={(error) => {
                                    log({
                                        message: 'Error loading Turnstile',
                                        level: 'error',
                                        error,
                                    });
                                }}
                            />
                        </div>

                        <Button loading={loading} size={'fullWidth'}>
                            <Small>Sign in with email</Small>
                        </Button>

                        <div className=" text-center text-xs text-muted-foreground">
                            <P>
                                By using PitchGhost you agree to our{' '}
                                <Link href="https://pitchghost.com/privacy">Privacy Policy</Link>.
                            </P>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
